
/*
======================
For - Big - Screen
======================
*/

@media screen and (min-width: 1920px) {

}


/*
=======================
For - Desktop - Screen
=======================
*/


@media screen and (min-width: 1200px) and (max-width: 1399px) {

	.container {
		max-width:1140px !important;
	}

	.home-page h2 {
		font-size: 38px;
	}

	.learn-content h1 {
		font-size: 62px;
		line-height: 70px;
	}

	.multiple-content p br {
		display: none;
	}

}


/*
=======================
For - Leptop - Screen
=======================
*/

@media screen and (min-width: 992px) and (max-width: 1199px) {

	.container {
		max-width:960px !important;
	}


	/*
	###################
	home-page start
	###################
	*/

	.home-page h2 {
		font-size: 33px;
		font-weight: 700;
		line-height: 35px;
		letter-spacing: 0;
	}

	/*
	----------------
	banner-area
	----------------
	*/

	.hdr-link li a {
		margin: 0 0 0 20px;
	}

	.hdr-btn {
		padding: 0 0 0 50px;
	}

	.hdr-btn li a, .hdr-btn li button {
		width: 120px;
		padding: 12px 0;
	}

	.learn-content h1 {
		font-size: 42px;
		line-height: 50px;
		letter-spacing: 0;
	}

	.video-a a:first-of-type {
		width: 180px;
		text-align: center;
		margin: 0 20px 0 0;
	}

	.learn-content > img {
		width: 924px;
		margin: 0 auto;
	}



	/*
	----------------
	trusted-area
	----------------
	*/

	.trusted-logo ul li {
		padding: 0 16px;
	}





	/*
	----------------
	customers-area
	----------------
	*/

	.customers-area {
		padding: 70px 0;
	}

	.customer-details {
		padding: 35px 20px 35px;
		margin: 0 0 24px;
	}

	.customer-details .column1 {
		width: 60px;
	}

	.customer-details h3 {
		font-size: 22px;
	}

	.customer-details p br {
		display: none;
	}

	.free-trial {
		padding: 30px 0 0;
	}




	/*
	----------------
	multiple-area
	----------------
	*/

	.multiple-area {
		padding: 100px 0;
	}

	.multiple-content p br {
		display: none;
	}

	.multiple-content p {
		padding: 0 12% 0 0;
	}

	.selected-link {
		border-radius: 11px;
		max-width: 360px;
		margin: 0 0 15px;
	}

	.selected-div {
		padding: 0px 30px 0 0 !important;
	}

	.selected-link > img {
		width: 20px;
	}

	.selected-link .d-flex {
	  	gap: 13px;
	}

	.selected-link div img {
		width: 30px;
	}

	.selected-link h2 {
		font-size: 35px;
	}




	/*
	----------------
	learns-area
	----------------
	*/

	.learns-area {
		padding: 0 0 100px;
	}

	.learns-area .row {
		padding: 0;
	}

	.learns-img img {
		width: 100%;
		margin: 20px 0 0;
	}




	/*
	----------------
	dedicated-area
	----------------
	*/

	.dedicated-area {
		padding: 50px 0 0;
	}

	.dedicated-content h2 br {
		display: none;
	}

	.dedicated-content img {
		width: 924px;
		margin: 0 auto;
	}



	/*
	----------------
	product-area
	----------------
	*/

	.product-area {
		padding: 60px 0 50px;
	}

	.product-head h2 {
		font-size: 28px;
		font-weight: 700;
		line-height: 28px;
		letter-spacing: 0;
	}

	.user-opinion {
		border-radius: 16px;
		padding: 36px 25px 40px 25px;
	}

	.user-opinion > p {
		font-size: 16px;
		line-height: 26px;
	}

	.products-row > div {
		margin: 0 0 12px;
	}



	/*
	----------------
	blog-area
	----------------
	*/

	.blog-area {
		padding: 0 0 60px;
	}

	.blog-head h2 {
		margin: 0 0 16px;
	}

	.blog-head p {
		padding: 0 13px;
		margin: 0 0 30px;
	}

	.blog-content {
		margin: 0 0 20px;
	}

	.blog-content small {
		margin: 16px 0 12px;
	}

	.blog-content h3 {
		font-size: 18px;
		line-height: 25px;
	}


	/*
	###################
	Sign-page start
	###################
	*/

	.sign-head {
		padding: 0 60px;
	}

	.sign-in-form {
		padding: 0 20px;
	}




	/*
	============================
	Instituation-page start here
	============================
	*/


	/*
	--------------------
	westmont-area
	--------------------
	*/

	.westmont-lnk {
		margin: 0 15px 0 0;
	}

	.westmont-input-btn > .d-flex {
		flex-wrap: wrap;
	}

	.btn__right {
		margin-top: 15px;
		margin-left: 0 !important;
		margin-bottom: 5px;
	}

	.search__input2 {
  		width: 300px;
	}

	.btn__right2 {
		margin: 0 0 0 auto !important;
	}

	.westmont-progress button {
		width: 150px;
	}



	/*
	============================
	New-page start here
	============================
	*/

	.simple-tabs .tabs li {
		margin: 0 5px 9px 0;
	}

	.simple-tabs .tab-content {
		margin: 20px 0 0;
	}


	/*
	============================
	Setings-area start here
	============================
	*/

	.settings-box {
		margin-left: 40px;
	}



	/*
	============================
	Jessie Page start here
	============================
	*/

	.session-content h5 {
		font-size: 13px;
	}

	.jessie-details > .d-flex > div button {
		width: 120px;
		font-size: 13px;
	}

	.jessie-details .status-div p {
		font-size: 12px;
	}

	.jessie-details .status-div p span {
		margin: 0 0 0 8px;
	}

	.red-letter,
	.green-letter {
		font-size: 11px;
	}



	/*---Confirmation-code---*/

	.code__flex .form-control {
		margin-right: 15px;
	}






}


/*
=======================
For - Ipad - Screen
=======================
*/

@media screen and (min-width: 768px) and (max-width: 991px) {


	.container {
		max-width:720px !important;
	}

	/*
	###################
	home-page start
	###################
	*/

	.home-page h2 {
		font-size: 28px;
		font-weight: 700;
		line-height: 26px;
		letter-spacing: 0;
	}



	/*
	----------------
	banner-area
	----------------
	*/

	.banner-area {
		padding: 24px 0 0;
	}

	.hdr-logo a img {
		width: 40px;
	}

	.hdr-link li a {
  		font-size: 13px;
  		margin: 0 0 0 12px;
	}

	.hdr-btn li:first-of-type a, .hdr-btn li:first-of-type button {
		margin: 0 3px 0 0;
	}

	.hdr-btn {
		padding: 0 0 0 25px;
	}

	.hdr-btn li a,
	.hdr-btn li button {
		width: 90px;
		font-size: 13px;
		padding: 10px 0;
		border-radius: 10px;
	}

	.learn-content h1 {
		font-size: 40px;
		line-height: 50px;
		letter-spacing: 0;
	}

	.learn-content p {
		margin: 12px 0 30px;
	}

	.video-a a:first-of-type {
		width: 180px;
		text-align: center;
		margin: 0 20px 0 0;
	}

	.learn-content > img {
		width: 680px;
		margin: 0 auto;
	}



	/*
	----------------
	trusted-area
	----------------
	*/

	.trusted-area {
		padding: 60px 0;
	}

	.trusted-logo > h3 {
		margin: 0 0 30px;
	}

	.trusted-logo ul li {
		padding: 0 10px;
	}

	.trusted-logo ul li a img {
		width: 145px;
	}




	/*
	----------------
	customers-area
	----------------
	*/

	.customers-area {
		padding: 70px 0;
	}

	.customer-details {
		padding: 35px 20px 35px;
		margin: 0 0 24px;
	}

	.customer-details .column1 {
		width: 60px;
	}

	.customer-details h3 {
		font-size: 20px;
	}

	.customer-details p br {
		display: none;
	}

	.free-trial {
		padding: 30px 0 0;
	}




	/*
	----------------
	multiple-area
	----------------
	*/

	.multiple-area {
		padding: 100px 0;
	}

	.multiple-content p br {
		display: none;
	}

	.multiple-content p {
		margin: 25px 0 30px;
		padding: 0 8% 0 0;
	}

	.selected-link {
		border-radius: 11px;
		padding: 13px 17px 15px;
		max-width: 260px;
		margin: 0 0 11px;
	}

	.selected-div {
		padding: 0px 10px 0 0 !important;
	}

	.selected-link > img {
		width: 13px;
		right: 11px;
		top: 11px;
	}

	.selected-link .d-flex {
	  	gap: 7px;
	}

	.selected-link div img {
		width: 17px;
	}

	.selected-link h4 {
		font-size: 10.045px;
		line-height: 25px;
	}

	.selected-link h2 {
		font-size: 22.959px;
		line-height: 28.699px;
		margin: 3px 0 0;
	}




	/*
	----------------
	learns-area
	----------------
	*/

	.learns-area {
		padding: 0 0 100px;
	}

	.learns-area .row {
		padding: 0;
	}

	.learns-img img {
		width: 100%;
		margin: 20px 0 0;
	}




	/*
	----------------
	dedicated-area
	----------------
	*/

	.dedicated-area {
		padding: 50px 0 0;
	}

	.dedicated-content h2 br {
		display: none;
	}

	.dedicated-content img {
		width: 680px;
		margin: 0 auto;
	}



	/*
	----------------
	product-area
	----------------
	*/

	.product-area {
		padding: 60px 0 50px;
	}

	.product-head h2 {
		font-size: 28px;
		font-weight: 700;
		line-height: 28px;
		letter-spacing: 0;
	}

	.user-opinion {
		border-radius: 16px;
		padding: 36px 25px 40px 25px;
	}

	.user-opinion > p {
		font-size: 16px;
		line-height: 26px;
	}

	.products-row > div {
		margin: 0 0 12px;
	}



	/*
	----------------
	blog-area
	----------------
	*/

	.blog-area {
		padding: 0 0 60px;
	}

	.blog-head h2 {
		margin: 0 0 16px;
	}

	.blog-head p {
		padding: 0 13px;
		margin: 0 0 30px;
	}

	.blog-content {
		margin: 0 0 20px;
	}

	.blog-content small {
		margin: 16px 0 12px;
	}

	.blog-content h3 {
		font-size: 18px;
		line-height: 25px;
	}


	/*
	----------------
	footer-area
	----------------
	*/

	.footer-area {
		padding: 50px 0 70px;
	}

	.footer-area .container {
 	 	padding: 0 25px;
	}

	.ftr-links h3 {
		font-size: 14.6px;
		margin: 0 0 8px;
	}

	.ftr-links ul li a {
		font-size: 13.5px;
	}

	.social-links ul li a {
		width: 30px;
		height: 30px;
		line-height: 2.1;
	}


	/*
	###################
	Sign-page start
	###################
	*/

	.dashboard-page h2 {
		font-size: 32px;
		line-height: 40px;
	}

	.clm-1 > h2 {
		padding: 0 0 0 35px;
	}

	.sign-head {
		padding: 0 30px;
	}

	.sign-in-area .clm-1 {
		width: 350px;
	}

	.clm-img {
		width: 100%;
	}

	.sign-in-area .clm-2 {
	  	width: calc(100% - 350px);
	}

	.sign-in-form {
		padding: 0 40px;
	}



	/*
	============================
	Instituation-page start here
	============================
	*/


	/*
	--------------------
	westmont-area
	--------------------
	*/

	.westmont-lnk {
		margin: 0 15px 0 0;
	}

	.westmont-input-btn > .d-flex {
		flex-wrap: wrap;
	}

	.westmont-input-btn > .d-flex > div {
		width: 100%;
	}

	.input__left > .d-flex {
		flex-wrap: wrap;
		gap: 0;
	}

	.input__left > .d-flex > div {
		width: 50%;
		margin: 0 0px 10px;
		padding: 0 5px;
	}

	.input__left2 > .d-flex > div {
		width: 33.33%;
	}

	.input__left2 > .d-flex {
		flex-wrap: nowrap;
	}

	.input__left2 .westmont-group1 {
		width: 360px !important;
	}

	.westmont-group input, .westmont-group select {
		width: 100%;
	}

	.btn__right > .d-flex {
		flex-wrap: wrap;
		gap: 0;
	}

	.btn__right > .d-flex > div {
		width: 50%;
		padding: 0 5px;
	}

	.std-select {
		background-position: 95% center;
	}

	.perform__btn button {
		width: 100%;
	}

	.add__btn button {
		width: 100%;
	}

	.westmont-details > .d-flex > div {
		width: 33.33%;
	}

	.westmont-area {
  		padding: 0 0 30px;
	}

	.westmont-details > .d-flex {
		flex-wrap: wrap;
		background: #FFF;
		padding: 20px 20px 25px;
		border-radius: 16px;
		margin-bottom: 12px;
	}

	.westmont-details {
		background: transparent;
		padding: 0;
	}

	.westmont-progress {
		text-align: center !important;
		width: 100% !important;
		padding: 20px 0 0;
	}

	.westmont-progress button {
		width: 100%;
		margin: 0 auto;
	}

	.add__btn2 {
		width: 33.33% !important;
	}

	.westmont-details2 > .d-flex > div:nth-of-type(3),
	.westmont-details2 > .d-flex > div:nth-of-type(4) {
		width: 50% !important;
		padding-left: 10px;
		padding-right: 10px;
	}

	.westmont-details2 > .d-flex > div:nth-of-type(3) button,
	.westmont-details2 > .d-flex > div:nth-of-type(4) button {
		width: 100%;
	}

	.westmont-mail {
		text-align: end;
	}




	/*
	============================
	New-page start here
	============================
	*/

	.simple-tabs .tab-content {
		margin: 20px 0 0;
	}

	.simple-tabs .tabs li {
		margin: 0 5px 9px 0;
	}




	/*
	============================
	Setings-area start here
	============================
	*/

	.account-settings .row {
		padding: 0;
	}

	.settings-div ul li a {
		width: 100%;
	}

	.settings-box {
		padding: 24px 16px 32px;
	}

	.settings-box input,
	.settings-box select {
		width: 100%;
	}

	.settings-div ul li a img {
		margin: 0 10px 0 0;
	}


	.dolar-div {
		padding: 16px;
		background-image: url('../../../src/static/images/bg-layer2.svg');
	}

	.dolar-div > .d-flex {
		gap: 18px;
		flex-direction: column;
		align-items: start;
	}

	.cription__div h4 {
		margin: 0 0 6px;
	}

	.cription__div {
		padding-right: 0;
		margin: 0 0 125px;
	}



	/*
	============================
	Jessie Page start here
	============================
	*/

	.jessie-box {
		gap: 10px;
	}

	 .jessie-box div h2 {
    	font-size: 25px;
  	}

  	.jessie-box > div {
  		max-width: 100%;
  		padding: 5px 10px 15px;
  	}

  	.jessie-details > .d-flex > div {
  		width: 50% !important;
  		padding: 0 10px;
  	}

  	.jessie-details > .d-flex > div:first-of-type {
		width: 50% !important;
		margin: 0 0 15px;
	}

	.jessie-details > .d-flex > div:nth-of-type(2) {
		width: 50% !important;
		margin: 0 0 15px;
	}

	.jessie-details > .d-flex > div button {
		width: 100%;
		margin: 0 0 15px;
	}

	.jessie-details .status-div {
		text-align: end;
	}




	/*---Confirmation-code---*/

	.code__flex .form-control {
		margin-right: 15px;
	}

	.start-learn-area {
		padding: 60px 0 50px;
	}








}





/*
=======================
For - Mobile - Screen
=======================
*/

@media screen and (max-width: 767px) {

	/*
	###################
	home-page start
	###################
	*/

	.home-page h2 {
		font-size: 24px;
		font-weight: 700;
		line-height: 26px;
		letter-spacing: 0;
	}





	/*------offcanvas-------*/

	.offcanvas-header {
		padding-right: 25px;
	}

	.offcanvas-title {
		color: #2E2C34;
		font-size: 26px;
		font-weight: 600;
		line-height: 40px;
	}

	.offcanvas-main .offcanvas {
		width: 275px;
		border: none;
	}

	.offcanvas-main .modal-backdrop.show {
		opacity: 1;
		background: rgba(151, 151, 151, 0.44);
	}

	.menu-link li a {
		color: #2E2C34;
		padding: 13px 12px;
		border-radius: 12px;
		background: transparent;
		display: block;
		margin: 0 0 5px;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
	}

	.menu-link li a:hover,
	.menu-link li a.active {
		background: rgba(0, 174, 164, 0.10);
		color: #000;
	}


	/*
	----------------
	banner-area
	----------------
	*/

	.banner-area {
		padding: 24px 0 0;
		background-image: url('../../../src/static/images/banner-bg-sm.svg');
	}

	.hdr-btn {
		margin: 15px 0 0;
	}

	.hdr-btn li a,
	.hdr-btn li button {
		width: 110px;
		font-size: 15px;
		padding: 15px 0;
	}

	.learn-content h1 {
		font-size: 38px;
		line-height: 50px;
		letter-spacing: 0;
	}

	.learn-content p {
		margin: 12px 0 30px;
	}

	.video-a {
		width: 200px;
		margin: 0 auto;
	}

	.video-a a:first-of-type {
		width: 180px;
		text-align: center;
		margin: 0 0 24px;
	}

	.learn-content > img {
		width: 100%;
	}



	/*
	----------------
	trusted-area
	----------------
	*/

	.trusted-area {
		padding: 30px 0 25px;
	}

	.trusted-logo > h3 {
		font-size: 18px;
		font-weight: 600;
		line-height: 24px;
		margin: 0 0 30px;
	}

	.trusted-logo ul {
		display: flex;
		flex-wrap: wrap;
	}

	.trusted-logo ul li {
		width: 50%;
		margin: 0 0 15px;
	}

	.trusted-logo ul li a img {
		width: 100%;
	}

	.fst-li {
		padding: 0 20px 0 6px !important;
	}

	.lst-li {
		padding: 0 6px 0 20px !important;
	}




	/*
	----------------
	customers-area
	----------------
	*/

	.customers-area {
		padding: 40px 0 50px;
	}

	.customer-details {
		padding: 35px 20px 35px;
		margin: 0 0 16px;
	}

	.customer-details .column1 {
		width: 60px;
	}

	.customer-details h3 {
		font-size: 20px;
	}

	.customer-details p br {
		display: none;
	}

	.free-trial {
		padding: 30px 0 0;
	}




	/*
	----------------
	multiple-area
	----------------
	*/

	.multiple-area {
		padding: 60px 0 80px;
	}

	.multiple-content p br {
		display: none;
	}

	.multiple-content p {
		margin: 25px 0 30px;
		padding: 0 8% 0 0;
	}

	.selected-link {
		border-radius: 11px;
		padding: 13px 17px 15px;
		max-width: 260px;
		margin: 0 0 11px;
	}

	.selected-div {
		padding: 0 !important;
		max-width: 390px;
		margin: 60px 0 0;
	}

	.selected-link > img {
		width: 13px;
		right: 11px;
		top: 11px;
	}

	.selected-link .d-flex {
	  	gap: 7px;
	}

	.selected-link div img {
		width: 17px;
	}

	.selected-link h4 {
		font-size: 10.045px;
		line-height: 25px;
	}

	.selected-link h2 {
		font-size: 22.959px;
		line-height: 28.699px;
		margin: 3px 0 0;
	}




	/*
	----------------
	learns-area
	----------------
	*/

	.learns-area {
		padding: 0 0 50px;
	}

	.learns-area .row {
		padding: 0;
	}

	.learns-img img {
		width: 100%;
		margin: 20px 0 0;
	}




	/*
	----------------
	dedicated-area
	----------------
	*/

	.dedicated-area {
		padding: 34px 0 0;
		background-image: url('../../../src/static/images/portal-img1.svg');
	}

	.dedicated-content h2 br {
		display: none;
	}

	.dedicated-content p {
		padding: 0 7%;
		margin: 12px 0 74px;
	}

	.dedicated-content p br {
		display: none;
	}

	.dedicated-content img {
		width: 100%;
	}



	/*
	----------------
	product-area
	----------------
	*/

	.product-area {
		padding: 60px 0 50px;
	}

	.product-head h2 {
		font-size: 24px;
		font-weight: 700;
		line-height: 28px;
		letter-spacing: 0;
		margin: 0 0 20px;
	}

	.user-opinion {
		border-radius: 16px;
		padding: 36px 25px 40px 25px;
	}

	.user-opinion > p {
		font-size: 16px;
		line-height: 26px;
	}

	.products-row > div {
		margin: 0 0 12px;
	}



	/*
	----------------
	blog-area
	----------------
	*/

	.blog-area {
		padding: 0 0 60px;
	}

	.blog-head h2 {
		margin: 0 0 16px;
	}

	.blog-head p {
		padding: 0 13px;
		margin: 0 0 30px;
	}

	.blog-head p br {
		display: none;
	}

	.blog-content {
		margin: 0 0 20px;
	}

	.blog-content small {
		margin: 16px 0 12px;
	}

	.blog-content h3 {
		font-size: 18px;
		line-height: 25px;
	}



	/*
	----------------
	footer-area
	----------------
	*/

	.footer-area {
		padding: 50px 32px 70px;
	}

	.footer-area .container {
  		padding: 0 16px;
	}

	.footer-row > div:first-of-type > div {
		margin: 0 0 30px;
	}

	.footer-row > div:nth-of-type(2) > div {
		margin: 0 0 90px;
	}

	.footer-row > div:nth-of-type(3) > div {
		margin: 0 0 60px;
	}

	.social-links {
		padding: 25px 0 0;
	}






	/*
	###################
	Sign-page start
	###################
	*/

	.dashboard-page h2 {
		font-size: 35px;
		line-height: 40px;
	}

	.sign-head {
		position: initial;
		padding: 20px 18px 15px;
		border-bottom: 1px solid #EBEAED;
		margin: 0 auto 45px;
		max-width: 540px;
	}

	.verify-head {
		margin: 0 auto 25px;
		max-width: 540px;
	}

	.verify__form h2 {
		font-size: 35px;
	}

	.sign-in-area .clm-2 {
		width: 100%;
	}

	.sign-in-form {
		padding: 0 18px;
		width: 100%;
		max-width: 540px;
	}

	.sign__form > h2 {
		margin: 0 0 15px;
	}

	.sign__form > p {
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		color: #84818A;
		margin: 0 0 30px;
	}

	.sign__form input {
		margin: 0 0 13px;
	}

	.group_inp a {
		margin: 0 0 18px;
	}

	.simple-text {
		margin: 0 0 27px;
	}




	/*
	============================
	Instituation-page start here
	============================
	*/

	.Instituation-header .container {
		width: 100%;
	}

	.show__head {
		padding: 20px 18px 15px;
	    border-bottom: 1px solid #EBEAED;
	    margin: 0 0 45px;
	}

	.pson-menu a {
		position: relative;
	}

	.pson-menu a img {
		position: absolute;
		right: 4px;
	}





	/*
	--------------------
	westmont-area
	--------------------
	*/

	.westmont-head h2 {
  		margin: 0 0 20px;
	}

	.westmont-lnk {
		width: 100%;
		max-width: 100%;
	}

	.westmont-lnk .d-flex {
		gap: 10px;
	}

	.westmont-lnk > img {
		width: 18px;
	}

	.westmont-lnk div img {
		width: 24px;
	}

	.westmont-input-btn > .d-flex {
		flex-wrap: wrap;
	}

	.westmont-input-btn > .d-flex > div {
		width: 100%;
	}

	.input__left .d-flex {
		flex-wrap: wrap;
		gap: 0;
	}

	.input__left .d-flex > div {
		width: 100%;
		margin: 0 0 10px;
	}

	.westmont-group input,
	.westmont-group select {
		width: 100%;
	}

	.btn__right {
		margin: 0 0 10px;
	}

	.btn__right .d-flex > div {
		width: 50%;
	}

	.btn__right .d-flex > div button {
		width: 100%;
	}

	.westmont-group .search__img-btn {
		right: 28px;
	}

	.perform__btn {
		margin: 15px 0 0 !important;
	}

	.perform__btn button {
		width: 100%;
		margin: 0 0 15px;
	}



	/*
	--------------------
	westmont-area
	--------------------
	*/

	.westmont-area {
  		padding: 0 0 30px;
	}

	.westmont-details > .d-flex {
		flex-wrap: wrap;
		background: #FFF;
		padding: 22px 16px 25px;
		border-radius: 16px;
		margin-bottom: 12px;
	}

	.westmont-details > .d-flex > div {
		width: 100% !important;
	}

	.westmont-progress button {
		width: 100%;
		text-align: center;
		margin: 30px 0 0;
	}

	.westmont-parent button {
		margin: 12px 0 0;
	}

	.westmont-mail a {
		color: #8F8F8F;
		margin: 0 0 0 7px;
	}

	.westmont-radio .form-group label::before {
		position: absolute;
		right: 0;
		top: 3px;
	}

	.westmont-radio p {
		margin: 0;
	}

	.westmont-radio label {
		display: block;
		position: relative;
	}

	.westmont-radio .form-group input:checked + label::after {
		top: 8px;
		right: 7px;
		left: initial;
	}

	.westmont-details {
		background: transparent;
		padding: 0;
	}

	.status-div {
		order: 2;
	}

	.westmont-mail {
		order: 1;
		margin: 0 0 6px;
	}

	.westmont-progress {
		order: 3;
	}



	/*---Modal---*/

	.modal-dialog-1,
	.modal-dialog-2,
	.modal-dialog-3,
	.modal-dialog-4 {
		padding: 0 10px;
	}

	.main-modal .modal-header p {
		margin-bottom: 20px;
	}

	.main-modal .modal-header p br {
		display: none;
	}

	.main-modal .modal-content {
		padding: 32px 16px;
	}




	/*
	============================
	New-page start here
	============================
	*/

	.simple-tabs .tabs li {
		margin: 0 5px 9px 0;
	}

	.simple-tabs .tab-content {
		margin: 20px 0 0;
	}

	.simple-tabs .tab-content textarea {
		height: 250px;
	}

	.westmont-area2 {
		padding-bottom: 60px;
	}




	/*
	============================
	Setings-area start here
	============================
	*/

	.account-settings .row {
		padding: 0;
	}

	.settings-div ul li a {
		width: 100%;
	}

	.settings-box {
		padding: 24px 16px 32px;
		margin: 0 0 85px;
	}

	.settings-box input,
	.settings-box select {
		width: 100%;
	}

	.profile-name-div {
		margin: 0 14px 0 0;
	}

	.dolar-div {
		padding: 16px;
		background-image: url('../../../src/static/images/bg-layer2.svg')
	}

	.dolar-div > .d-flex {
		gap: 18px;
		flex-direction: column;
		align-items: start;
	}

	.cription__div h4 {
		margin: 0 0 6px;
	}

	.cription__div {
		padding-right: 0;
		margin: 0 0 45px;
	}




	/*
	============================
	Jessie Page start here
	============================
	*/

	.profile-name-link {
		right: 0;
		width: 100%;
		min-width: 150px;
	}

	.jessie-flex-div .westmont-head {
		width: 40%;
	}

	.jessie-box {
		flex-direction: column;
		gap: 12px;
	}

	.jessie-box > div {
		width: 100%;
	}

	.jessie-box div h2 {
		font-size: 26px;
		font-weight: 500;
		line-height: 40px;
		margin: 15px 0 0;
	}

	.jessie-details .status-div {
		order: 0;
		text-align: end;
	}

	.jessie-details .status-div p span {
	  	margin: 0 0 0 9px;
	}

	.jessie-details > .d-flex {
		align-items: center;
	}

	.jessie-details > .d-flex > div:first-of-type {
		width: 45% !important;
		margin: 0 0 12px;
	}

	.jessie-details > .d-flex > div:nth-of-type(2) {
		width: 55% !important;
		margin: 0 0 12px;
	}

	.session-content h5 {
		padding: 0;
	}

	.jessie-details > .d-flex > div button {
		width: 100%;
		margin: 0 0 12px;
	}

	.sort__div {
		display: flex;
	}

	.sort__div select {
		width: 50% !important;
		background-position: 90% center !important;
	}

	.inr-attempt .wdt-sm-div {
		width: 45%;
	}

	.progress__div {
		margin: 0 7px;
	}

	.main-modal3 .modal-content {
		padding-left: 15px;
		padding-right: 15px;
	}

	.inr-attempt p,
	.inr-attempt span {
		font-size: 13px;
	}

	.main-modal3 .modal-header button {
		right: 17px;
		top: 5px;
		font-size: 13px;
	}

	.inr-attempt span {
		margin: 0 0 0 7px;
	}

	.practice___modal .settings-box {
		margin: 0;
	}

	.main-modal44 .modal-header button {
		right: 17px;
    	top: 5px;
	}

	.spelling-div table tr th:nth-of-type(2),
	.spelling-div table tr td:nth-of-type(2) {
		padding-left: 15px;
	}

	/*---Confirmation-code---*/
	.code__flex .form-control {
		margin-right: 15px;
	}

	.get__code {
		margin: 44px 0 20px;
	}

	.start-learn-area {
		padding: 35px 0 0;
	}

	.quizz-area {
	  	padding-top: 130px;
	  	padding-bottom: 130px;
	}




	/*
	============================
	writenspeak start here
	============================
	*/

	.content-box {
		padding: 15px 15px;
		min-height: 570px;
		flex-direction: column;
	}

	.content-box .text-of-typing {
    width: 100%;
    min-height: 570px;
    /*! padding-right: 50px; */
  }


	.content-box > img {
		position: absolute;
		right: 15px;
		top: 15px;
	}

	.test-mode {
		width: 100% !important;
	}

	.test-mode textarea {
		padding-right: 50px;
	}

	 #wordCount {
    width: 100% !important;
    margin: 0 auto -20px !important;
  }

	.practise-footer {
		padding: 20px 15px;
	}

	.practise-footer button {
			padding: 15px 20px;
			font-size: 16px;
	}

	.content-box .text-of-typing p {
		font-size: 20px;
		padding-right: 50px;
	}


	/*chart-area*/

	.graph-area {
	  border-radius: 10px;
	  padding: 15px 10px;
	}

	.select-date select {
	  border-radius: 10px;
	  font-size: 12px;
	  padding: 8px 35px 8px 16px;
	}

}


@media screen and (min-width: 576px) and (max-width: 767px) {

	.container {
		max-width: 540px !important;
	}

}


@media screen and (max-width: 389px) {

	.inr-attempt {
		padding: 16px 8px;
	}

	.code__flex .form-control {
		width: 70px;
		height: 90px;
	}

}

@media (max-width: 768px) {
	.practice__dropdown_box {
		flex-direction: column;
		align-items: stretch;
	}

	.sort__div,
	.practice__btn__div {
		width: 100%;
	}

	.sort__div {
		margin-top: .5rem;
	}

	.sort__div select,
	.practice__btn {
		width: 100%;
	}

	.practice__btn__div {
		display: flex;
		order: -1;
		justify-content: space-between;
		align-items: center;
	}

	.settings-button {
		width: auto; /* Maintain the original size */
	}

}


@media screen and (max-width: 575px) {

	.ws-wordbox h4 {
		display: block;
		font-size: 25px;
	}

	.ws-inner img {
		margin-right: 10px;
	}

	.wslang-option {
		width: 100%;
		justify-content: start;
		margin-top: 10px;
	}

	.ws-wordbox {
		margin: 38px 5px 10px 10px;
	}

}
