@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

:root {
    --blue-gray-900: #0F172A;
    --gray-600: #52525B;
    --gray-900: #1a202c;
    --Black: #12141D;
    --White: #FFF;
}

h1,
h2,
h3,
h4,
h5,
h6,
th,
td,
p {
    margin: 0;
}

figure {
    margin: 0;
}

body {
    background: #FFF;
    font-family: "Inter", sans-serif;
}

span,
small {
    display: inline-block;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    display: inline-block;
    transition: 0.3s all ease-in-out;
    font-size: 16px;
    font-weight: 600;
    color: #FFF;
}

button {
    border: none;
    background: transparent;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

button:focus {
    outline: none;
    box-shadow: none !important;
}

input:focus,
textarea:focus {
    outline: none;
    border: none;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}


.content-box ::selection {
    background: #00B57A;
    color: #FFFFFF;
}

.content-box ::-webkit-color-swatch-wrapper {
    background: #00B57A;
    color: #FFFFFF;
}

.content-box ::-moz-selection {
    background: #00B57A;
    color: #FFFFFF;
}

::placeholder {
    opacity: 1;
    color: #898989;
    letter-spacing: 0 !important;
}

/*--------------------
 Back to Top Button
---------------------*/

.scrollToTopBtn {
    background-color: #021626;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    font-size: 20px;
    line-height: 0;
    width: 45px;
    height: 45px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    opacity: 0;
    transform: translateY(100px);
    transition: all .5s ease;
    box-shadow: 0 0 13px rgba(255, 255, 255, 0.8);
}

.showBtn {
    opacity: 1;
    transform: translateY(0)
}


/*
========================
Home-Page start here
========================
*/

.home-page .container {
    max-width: 1315px;
    padding: 0 18px;
}

.home-page h2 {
    color: var(--blue-gray-900);
    font-size: 42px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -1px;
}

.home-page p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--Black);
}

.home-page small {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    opacity: 0.7;
    color: var(--Black);
}


/*
----------------
banner-area
----------------
*/

.banner-area {
    background-image: url('../../../src/static/images/banner-bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 40px 0 0;
}

.banner-area .container {
    max-width: 1335px;
    padding: 0 18px;
}

.hdr-link li a {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14.414px;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 0 38px;
    position: relative;
}

.hdr-link li a::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1.5px;
    background: rgba(255, 255, 255, 0.8);
    transition: 0.3s all ease-in-out;
    left: 0;
    right: 0;
    bottom: -4px;
    margin: 0 auto;
}

.hdr-link li a:hover::before,
.hdr-link li a.active::before {
    width: 100%;
    transition: 0.3s all ease-in-out;
}

.hdr-btn {
    padding: 0 0 0 80px;
}

.hdr-btn li a,
.hdr-btn li button {
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    width: 130px;
    text-align: center;
    border-radius: 12px;
    border: 1px solid transparent;
    padding: 19px 0;
}

.hdr-btn li:first-of-type a,
.hdr-btn li:first-of-type button {
    color: #00AEA4;
    border: 1px solid #00AEA4;
    background: transparent;
    margin: 0 12px 0 0;
}

.hdr-btn li:last-of-type a,
.hdr-btn li:last-of-type button {
    background: #00AEA4;
}

.hdr-btn li:first-of-type a:hover,
.hdr-btn li:first-of-type button:hover {
    box-shadow: 1px 0px 10px #00AEA4;
    opacity: 0.9;
}

.hdr-btn li:last-of-type a:hover,
.hdr-btn li:last-of-type button:hover {
    background: #00B67A;
    color: #FFF;
    box-shadow: 1px 0px 5px #00B67A;
}

.learn-content > div {
    padding: 70px 0 45px;
}

.learn-content h1 {
    color: var(--White);
    font-size: 80px;
    font-weight: 700;
    line-height: 84px;
    letter-spacing: -2px;
}

.learn-content p {
    color: rgba(250, 250, 250, 0.7);
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    margin: 20px 0 24px;
}

.video-a a:first-of-type {
    border-radius: 12px;
    background: #00AEA4;
    line-height: 1;
    padding: 19px 16px;
    margin: 0 45px 0 0;
}

.video-a a:first-of-type:hover {
    background: #00B67A;
    color: #FFF;
    box-shadow: 1px 0px 5px #00B67A;
}

.video-a a:last-of-type {
    font-weight: 500;
    color: rgba(250, 250, 250, 0.8);
}

.video-a a img {
    margin: 0 12px 0 0;
}



/*
----------------
trusted-area
----------------
*/

.trusted-area {
    padding: 85px 0;
}

.trusted-logo > h3 {
    color: var(--gray-900);
    font-size: 21px;
    font-weight: 700;
    line-height: 32px;
    margin: 0 0 50px;
}

.trusted-logo ul li {
    padding: 0 40px;
}



/*
----------------
customers-area
----------------
*/

.customers-area {
    background: #F6F7F7;
    padding: 100px 0;
}

.customer-head h2 {
    margin: 0 0 45px;
}

.customer-details {
    border-radius: 10px;
    background: #FFF;
    padding: 35px 30px 40px;
    margin: 0 0 25px;
}

.customer-details .column1 {
    width: 55px;
}

.customer-details .column2 {
    width: 90%;
}

.customer-details h3 {
    color: var(--Black);
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
}

.customer-details p {
    margin: 18px 0 32px;
}

.customer-details a {
    color: var(--Black);
}

.customer-details a:hover {
    color: #00AEA4;
}

.customer-details a i {
    margin: 0 0 0 9px;
}

.free-trial {
    padding: 45px 0 0;
}

.free-trial-a {
    border-radius: 12px;
    background: #00AEA4;
    padding: 15px 28px;
}

.free-trial-a:hover {
    background: #00B67A;
    color: #FFF;
    box-shadow: 1px 0 5px #00B67A;
}



/*
----------------
multiple-area
----------------
*/

.multiple-area {
    padding: 175px 0;
}

.selected-link {
    border-radius: 20px;
    background: #FFF !important;
    max-width: 475px;
    box-shadow: 0 26.189px 39.283px rgba(0, 0, 0, 0.07);
    padding: 24px 30px 28px;
    margin: 0 0 20px;
    position: relative;
}

.selected-link > img {
    position: absolute;
    right: 20px;
    top: 20px;
}

.selected-link .d-flex {
    gap: 13px;
}

.selected-link h4 {
    color: #2E2C34;
    font-size: 18.332px;
    font-weight: 500;
    line-height: 26.189px;
}

.selected-link h4 img {
    margin: 0 12px 0 0;
}

.selected-link h2 {
    font-weight: 500;
    letter-spacing: 0;
    font-size: 41.5px;
    margin: 18px 0 0;
}

.multiple-content p {
    opacity: 0.6;
    margin: 25px 0 45px;
}



/*
----------------
learns-area
----------------
*/

.learns-area {
    padding: 0 0 155px;
}

.learns-area .row {
    padding: 0 50px;
}



/*
----------------
dedicated-area
----------------
*/

.dedicated-area {
    background-image: url('../../../src/static/images/portal-img.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 75px 0 0;
}

.dedicated-content h2 {
    color: var(--White);
}

.dedicated-content p {
    color: var(--White);
    margin: 25px 0 60px;
}



/*
----------------
product-area
----------------
*/

.product-area {
    padding: 124px 0 75px;
}

.product-head h2 {
    font-family: "Plus Jakarta Sans", sans-serif;
    color: var(--gray-900);
    font-size: 46px;
    font-weight: 800;
    line-height: 48px;
    letter-spacing: -1px;
    margin: 0 0 45px;
}

.products-row > div {
    margin: 0 0 25px;
}

.user-opinion {
    border-radius: 26px;
    background: #F6F7F7;
    padding: 36px 35px 40px 40px;
    height: 100%;
}

.user-column1 {
    width: 70px;
}

.user-column2 {
    padding: 0 0 0 20px;
}

.user-column2 h3 {
    color: var(--Black);
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
}

.user-opinion > p {
    color: var(--Black);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin: 20px 0 0;
}



/*
----------------
blog-area
----------------
*/

.blog-area {
    padding: 0 0 110px;
}

.blog-head h2 {
    margin: 0 0 20px;
}

.blog-head p {
    opacity: 0.7;
    margin: 0 0 60px;
}

.blog-content small {
    color: var(--gray-600);
    margin: 30px 0 15px;
}

.blog-content h3 {
    color: var(--Black);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}




/*
----------------
footer-area
----------------
*/

.footer-area {
    background: #021626;
    padding: 50px 0 90px;
}

.footer-area .container {
    padding: 0 40px;
}

.ftr-links h3 {
    color: #FFF;
    font-size: 15.672px;
    font-weight: 700;
    line-height: 25.5px;
    margin: 0 0 12px;
}

.ftr-links ul li a {
    color: #BABABA;
    font-size: 14.531px;
    font-weight: 400;
    line-height: 1;
    margin: 0 0 10px;
}

.ftr-links ul li a:hover {
    color: #00AEA4;
}

.social-links {
    padding: 20px 0 0;
}

.social-links ul li a {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #FFF;
    background: transparent;
    color: #FFF;
    font-size: 20px;
    line-height: 1.8;
    text-align: center;
    margin: 0 4px 0 0;
}

.social-links ul li a:hover {
    color: #000;
    background: #FFF;
}


/*
========================
Home-Page end here
========================
*/



/*
========================
Sign-in page start here
========================
*/

/*--sign-page1--*/

.dashboard-page h2 {
    color: #2E2C34;
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
}

.dashboard-page p,
.dashboard-page a {
    color: #202020;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
}

.sign-in-area .container {
    max-width: 100% !important;
    padding: 0;
}

.sign-head {
    position: absolute;
    width: 100%;
    padding: 0 80px;
    top: 25px;
}

.sign-in-area .clm-1 {
    width: 535px;
    background: #F6F7F7;
    min-height: 100vh;
    padding: 250px 0 60px;
}

.sign-in-area .clm-2 {
    width: calc(100% - 535px);
}

.clm-head a img {
    width: 35px;
}

.clm-img {
    margin: 0 0 20px;
}

.clm-1 > h2 {
    padding: 0 0 0 70px;
    color: #000;
}

.simple-text p {
    color: #202020;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.simple-text p a {
    color: #00AEA4;
    font-weight: 600;
}

.sign-in-form {
    max-width: 420px;
    margin: 0 auto;
}

.sign__form > h2 {
    margin: 0 0 45px;
}

.group_inp {
    position: relative;
}

.eye-div {
    position: absolute;
    top: 12px;
    right: 15px;
    cursor: pointer;
}

.eye-div-show::before {
    content: '/';
    position: absolute;
    top: -3px;
    right: 8px;
    font-size: 20px;
    font-weight: 500;
    transform: rotate(23deg);
    color: #898989;
}

.sign__form input {
    color: #2E2C34;
    width: 100%;
    background: #F6F7F7;
    border-radius: 12px;
    border: none;
    padding: 16px 18px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin: 0 0 15px;
    border: 1px solid transparent;
    letter-spacing: 0.2px;
}

.sign__form input:focus {
    border: 1px solid #00AEA4;
    background: #fff;
}

.sign__form input[type="password"] {
    letter-spacing: 2px;
}

.group_inp a {
    color: #00AEA4;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin: 0 0 25px;
}

.sign__form button[type="submit"],
.sign__form button[type="button"] {
    width: 100%;
    display: block;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
    background: #00AEA4;
    padding: 16px 0;
    color: #FFF;
}

.sign__form button:hover {
    background: #00B67A;
    color: #FFF;
    box-shadow: 1px 0px 5px #00B67A;
}


/*--Create-page--*/

.ratio__div p {
    margin: 0 0 15px;
}

.ratio__div label {
    width: 100%;
    height: 48px;
}

.ratio__div label span {
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    top: 15px;
    left: 18px;
}

.ratio__div [type="radio"]:checked,
.ratio__div [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.ratio__div [type="radio"]:checked + label,
.ratio__div [type="radio"]:not(:checked) + label
{
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

.ratio__div [type="radio"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #00AEA4;
    border-radius: 12px;
    background: #fff;
}

.ratio__div [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #F6F7F7;
    border-radius: 12px;
    background: #F6F7F7;
}

.ratio__div [type="radio"]:checked + label:after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 13px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-image: url('../../../src/static/images/checked.svg');
    background-repeat: no-repeat;
}

.ratio__div [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.ratio__div [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}



/*---verify-identity---*/

.verify__form > span {
    color: #202020;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 40px;
}



/*---Confirmation-code---*/

.code__flex .form-control {
    display: block;
    height: 96px;
    margin-right: 30px;
    text-align: center;
    width: 78px;
    color: #202020;
    font-size: 38px;
    font-weight: 400;
    line-height: normal;
    border-radius: 18px;
    border: 0.5px solid rgba(0, 0, 0, 0.14);
    background: #FFF;
    margin-bottom: 0;
}

.code__flex .form-control:focus {
    outline: none;
    box-shadow: none;
    border: 0.5px solid rgba(0, 0, 0, 0.14);
}

.get__code {
    margin: 44px 0 50px;
}

.get__code a {
    color: #00AEA4;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-decoration-line: underline;
}

.resend-div {
    padding: 18px 18px 32px;
    border-radius: 18px;
    background: #F5F7F6;
}

.resend-div p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin: 0 0 12px;
}


/*
========================
Sign-in page end here
========================
*/




/*
============================
Instituation-page start here
============================
*/

.Instituation-body {
    background: #F6F7F7;
}

.Instituation-page button {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}



/*
--------------------
Instituation-header
--------------------
*/

.Instituation-header {
    padding: 10px 0;
    border-bottom: 1px solid #EBEAED;
    margin: 0 35px 60px;
}

.Instituation-page .container {
    max-width: 1175px;
    padding: 0 18px;
}

.wdt-img {
    width: 33px;
}

.log-btn ul li button {
    font-weight: 500;
    margin: 0 0 0 12px;
    padding: 13px 16px;
    border-radius: 12px;
    background: transparent;
}

.log-btn ul li button:hover {
    opacity: 0.7;
}

.log-btn ul li:last-of-type button.subs-btn,
.perform__btn button.subscribe {
    background: #00AEA4;
    color: #FFF;
    opacity: 1;
}

.log-btn ul li:last-of-type button.subs-btn:hover,
.perform__btn button.subscribe:hover {
    background: #00B67A;
    color: #FFF;
    box-shadow: 1px 0px 5px #00B67A;
    opacity: 1;
}



/*
--------------------
westmont-area
--------------------
*/

.westmont-area {
    padding: 0 0 75px;
}

.westmont-head h2 {
    margin: 0 0 40px;
}

.westmont-box {
    margin: 0 0 18px;
}

.westmont-lnk {
    width: 365px;
    box-shadow: none;
    padding: 18px 25px 20px;
    margin: 0 24px 0 0;
}

.westmont-lnk > img {
    width: 18px;
}

.quest-mark {
    position: relative;
    cursor: pointer;
}

.quest-mark img {
    width: 18px;
    position: absolute;
    right: 0px;
    top: 0px;
}

.quest-mark:hover .quest-txt {
    opacity: 1;
}

.quest-txt {
    background: #000;
    padding: 8px;
    border-radius: 5px;
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 150px;
    z-index: 1;
    opacity: 0;
}

.quest-txt p {
    font-size: 10px;
    line-height: 1.6 !important;
    color: #fff !important;
}

.westmont-lnk div h2 {
    font-size: 32px;
    font-weight: 500;
    margin: 6px 0 0;
}

.westmont-lnk div p {
    color: #2E2C34;
    line-height: 26px;
}

.input__left .d-flex,
.btn__right .d-flex {
    gap: 12px;
}

.westmont-group {
    position: relative;
}

.westmont-group input,
.westmont-group select {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    height: 46px;
    border-radius: 12px;
    background-color: #FFFFFF;
    border: none;
    color: #2E2C34;
    padding: 0 18px;
}

.search__input {
    width: 222px;
}

.search__input2 {
    width: 365px;
}

.search__input3 {
    width: 280px;
}

.westmont-group input::placeholder {
    color: #2E2C34;
}

.westmont-group .search__img-btn {
    position: absolute;
    right: 12px;
    top: 10px;
    padding: 0;
}

.std-select {
    width: 185px;
    background-image: url('../../../src/static/images/arrow_drop_down.svg');
    background-repeat: no-repeat;
    background-position: 90% center;
}

.std-select2 {
    width: 208px;
}

.perform__btn button {
    font-weight: 500;
    color: #FFFFFF;
    background: #3F3F3F;
    border-radius: 12px;
    padding: 13px 16px;
}

.add__btn button {
    background: #EBECEC;
    color: #3F3F3F;
    font-weight: 500;
    padding: 13px 17px;
    border-radius: 12px;
}

.add__btn button img {
    margin: 0 8px 0 0;
}

.westmont-details {
    background: #FFF;
    border-radius: 16px;
    padding: 10px 20px 50px;
    margin: 10px 0 0;
    min-height: 34rem;
}

.westmont-details > .d-flex {
    flex-wrap: wrap;
}

.westmont-details > .d-flex > div {
    width: 25%;
}

.westmont-details2 > .d-flex > div:first-of-type {
    width: 30%;
}

.westmont-details2 > .d-flex > div:nth-of-type(2) {
    width: 32%;
}

.westmont-details2 > .d-flex > div:nth-of-type(3),
.westmont-details2 > .d-flex > div:nth-of-type(4) {
    width: 19%;
}

.westmont-details > .d-flex {
    justify-content: space-between;
    border-bottom: 1px solid #F3F2F5;
    padding: 15px 0;
}

.westmont-radio .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.westmont-radio .form-group label {
    position: relative;
    cursor: pointer;
    margin: 0 0 5px;
}

.westmont-radio .form-group label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #D8D8D8;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.westmont-radio .form-group input:checked + label:before {
    background: #00D455;
    border: 1px solid #00D455;
}

.westmont-radio .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 7.9px;
    left: 6.8px;
    width: 6px;
    height: 9px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.westmont-radio2 .form-group label:before,
.westmont-radio2 .form-group label:after {
    display: none;
}

.status-div p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: "Manrope", sans-serif;
}

.status-div p span {
    margin: 0 0 0 12px;
}

.status-div p span.green {
    color: #238C12;
}

.status-div p span.red {
    color: #BA1717;
}

.status-div p span.orange {
    color: #FF9141;
}

.status-div p span.yellow {
    color: #BB981C;
}

.westmont-radio p,
.westmont-mail span {
    font-size: 14px;
    color: #2E2C34;
    line-height: 20px;
    font-weight: 600;
    margin: 0 0 0 20px;
    font-family: "Manrope", sans-serif;
}

.westmont-mail a {
    color: #2E2C34;
}

.westmont-progress button {
    color: #2E2C34;
    font-weight: 400;
    border: 1px solid #ECEDED;
    border-radius: 12px;
    padding: 0;
    height: 40px;
    width: 180px;
}

.westmont-parent button img {
    margin: 0 14px 0 0;
}


/*---Modal---*/

.modal-backdrop.show {
    background: rgba(151, 151, 151, 0.44);
    opacity: 1;
}

.main-modal .modal-dialog-1 {
    max-width: 450px;
}

.main-modal .modal-content {
    border-radius: 16px;
    background: #FFF;
    padding: 34px 37px;
}

.main-modal .modal-header {
    position: relative;
}

.main-modal .modal-header h5 {
    color: #2E2C34;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}

.main-modal .modal-header p {
    color: #2E2C34;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 12px 0 28px;
}

.main-modal .modal-header button {
    font-size: 13px;
    color: #898989;
    opacity: 0.5;
    padding: 0;
    margin: 0;
    font-weight: 400;
    position: absolute;
    right: 0;
    top: 5px;
    border-radius: 0;
    z-index: 100;
}

.copy-input .tooltip1 {
    position: absolute;
    display: inline-block;
    right: 8px;
    top: 8px;
}

.copy-input .tooltip1 .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -62px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
}

.copy-input .tooltip1 .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.copy-input .tooltip1:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.copy-input label {
    color: #2E2C34;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 12px;
}

.copy-input {
    margin: 0 0 16px;
}

.copy-input input {
    border-radius: 12px;
    border: 1px solid #ECEDED;
    background: #FFF;
    padding: 14px 12px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: block;
    width: 100%;
}

.tool-psn-div {
    position: relative;
}

.modal-footer1 {
    display: flex;
    justify-content: space-between;
    padding: 18px 0 0 !important;
}

.modal-footer1 button.btn-1st {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 12px;
    border: 1px solid #ECEDED;
    background: #FFF;
    padding: 12px 16px;
}

.modal-footer1 button.btn-2nd {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 12px;
    background: #00AEA4;
    padding: 12px 16px;
}

.modal-footer1 button.btn-2nd:hover {
    background: #00B67A;
    color: #FFF;
    box-shadow: 1px 0px 5px #00B67A;
    opacity: 1;
}



/*
============================
Instituation-page end here
============================
*/



/*
============================
New-page start here
============================
*/

.simple-tabs .tabs {
    margin: 24px 0 0;
}

.simple-tabs .tabs li {
    display: inline-block;
    margin:0 8px 0 0;
    cursor: pointer;
    font-weight: 500;
    color: #3F3F3F;
    background: #EBECEC;
    border-radius: 12px;
    padding: 13px 16px;
    font-size: 14px;
    line-height: 20px;
}

.simple-tabs .tab-content {
    display: none;
    margin: 40px 0 0;
}

.simple-tabs .tab-content textarea {
    padding: 15px;
    border-radius: 20px;
    background: #FFF;
    border: none;
    width: 100%;
    height: 450px;
}

.simple-tabs .tab-content.current {
    display: inherit;
}

.add__sub__btn button {
    border-radius: 12px;
    background: #00AEA4;
    padding: 13px 32px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 24px 0 0;
}

.add__sub__btn button:hover {
    background: #00B67A;
    color: #FFF;
    box-shadow: 1px 0px 5px #00B67A;
    opacity: 1;
}


/*
============================
New-page end here
============================
*/



/*
============================
Setings-area start here
============================
*/

.account-settings .row {
    /*! padding: 0 60px; */
}

.settings-div > p {
    color: #84818A;
    margin: 8px 0 24px;
}

.settings-div ul li a {
    color: #2E2C34;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 13px 12px;
    border-radius: 12px;
    background: transparent;
    width: 265px;
    margin: 0 0 2px;
}

.settings-div ul li a img {
    margin: 0 15px 0 0;
    transition: 0.3s all ease-in-out;
}

.settings-div ul li a:hover,
.settings-div ul li a.active {
    background: rgba(0, 174, 164, 0.10);
    color: #000;
}

.settings-div ul li a:hover img,
.settings-div ul li a.active img {
    transition: 0.3s all ease-in-out;
    filter: invert(44%) sepia(34%) saturate(2399%) hue-rotate(143deg) brightness(98%) contrast(101%);
}

.settings-box {
    border-radius: 16px;
    background: #FFF;
    padding: 24px 24px 40px;
    max-width: 100%;
}

.settings-box3 {
    padding-bottom: 24px;
}

.settings-box h3 {
    color: #2E2C34;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 30px;
}

.settings-box2 h3 {
    margin: 0 0 25px;
}

.settings-box h5 {
    color: #2E2C34;;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.settings-box p {
    color: #84818A;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 4px 0 22px;
}

.settings-box p b {
    font-weight: 500;
}

.settings-box input,
.settings-box select {
    border-radius: 12px;
    border: 1px solid #ECEDED;
    background: #FFF;
    width: 375px;
    padding: 13px 12px;
    color: #2E2C34;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 16px;
    display: block;
}

.settings-box select {
    color: #000;
    background-image: url('../../../src/static/images/arrow_drop_down1.svg');
    background-repeat: no-repeat;
    background-position: 96% center;
}

.settings-box input::placeholder {
    color: #84818A;
}

.settings-box button.change__btn {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 12px;
    background: #00AEA4;
    padding: 13px 17px;
    margin: 14px 0 0;
}

.settings-box button.change__btn:hover {
    background: #00B67A;
    color: #FFF;
    box-shadow: 1px 0 5px #00B67A;
    opacity: 1;
}

.profile-name-div {
    display: flex;
    justify-content: flex-end;
}

button.name__profile {
    color: #2E2C34;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    font-family: "Manrope", sans-serif;
}

button.name__profile img {
    margin: 0 0 0 2px;
}

.profile-name-link {
    background: #FFF;
    border-radius: 5px;
    width: 100px;
    position: absolute;
    right: -20px;
    padding: 5px 5px 8px;
    text-align: start;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    top: 29px;
    display: none;
    z-index: 1;
}

.profile-name-link li a {
    display: block;
    width: 100%;
    padding: 4px 7px;
    margin: 0 0 2px;
    border-radius: 4px;
}

.profile-name-link li a:hover {
    background: rgba(0, 0, 0, 0.1);
}

.dolar-div {
    background-image: url('../../../src/static/images/bg-layer.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 18px 24px;
    border-radius: 16px;
}

.dolar-div h3 {
    color: #FF9141;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    padding: 8px 12px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.08);
    margin: 0;
}

.dolar-div h4 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.dolar-div > .d-flex {
    gap: 16px;
    align-items: center;
}

.cription__div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 52px 12px 20px 0;
    border-bottom: 1px solid #F3F2F5;
    margin: 0 0 42px;
}

.cription__div h4 {
    color: #2E2C34;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    vertical-align: middle;
}

.crip__div1 img {
    width: 36px;
}

.red-letter {
    color: #FF9141;
    font-family: "Manrope", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    border-radius: 6px;
    background: rgba(255, 145, 65, 0.10);
    padding: 3px 8px;
}

.green-letter {
    color: #20C9AC;
    font-family: "Manrope", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    border-radius: 6px;
    background: rgba(32, 201, 172, 0.10);
    padding: 3px 8px;
}

.crip__div2 p {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 0 7px;
}

.crip__div3 span {
    color: #00D455;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.crip__div3 span.red {
    color: #BC2020;
}

.space-btn-div {
    justify-content: space-between;
}

.space-btn-div .pause__btn {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 12px;
    border: 1px solid #ECEDED;
    background: #FFF;
    width: 74px;
    height: 46px;
}



/*--main-modal2--*/

.main-modal2 .modal-dialog-2 {
    max-width: 550px;
}

.main-modal2 .modal-content {
    padding: 48px 50px;
}

.copy-input2 {
    margin: 30px 0 0;
}

.copy-input2 input {
    margin: 0 0 16px;
}

.copy-input2 input::placeholder {
    color: #000;
}


/*
============================
Setings-area end here
============================
*/




/*
============================
Jessie Page start here
============================
*/

.practice__btn__div .practice__btn {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 12px;
    background: #00AEA4;
    padding: 13px 17px;
    margin: 0 8px 0 0;
}

.practice__btn__div .practice__btn:hover {
    background: #00B67A;
    color: #FFF;
    box-shadow: 1px 0px 5px #00B67A;
    opacity: 1;
}

.jessie-box {
    display: flex;
    gap: 24px;
}

.jessie-box > div {
    margin: 0;
    width: 33.33%;
}

.wdt-sml {
    width: 24px;
}

.sort__div {
    padding: 6px 0 0;
}

.sort__div p {
    color: #2E2C34;
    font-weight: 500;
    margin: 0 12px 0 0;
}

.sort__div select:first-child {
    border-radius: 12px;
    border: 1px solid #E5E6E6;
    background: #F6F7F7 url('../../../src/static/images/arrow_drop_down.svg') no-repeat 121px center;
    color: #2E2C34;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 10px 16px;
    width: 150px;
}

.sort__div select:nth-child(2) {
    border-radius: 12px;
    border: 1px solid #E5E6E6;
    background: #F6F7F7 url('../../../src/static/images/arrow_drop_down.svg') no-repeat 121px center;
    color: #2E2C34;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 10px 16px;
    width: 150px;
    margin-left: 12px;
}


.session-content h5 {
    color: #2E2C34;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 0 0 0 6px;
}

.jessie-details > .d-flex > div {
    width: 16.66%;
}

.jessie-details > .d-flex > div button {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border-radius: 12px;
    width: 150px;
    text-align: center;
    height: 40px;
    border: 1px solid #ECEDED;
}

.westmont-score button {
    color: #00AEA4;
    border: 1px solid #00AEA4 !important;
    background: rgba(0, 174, 164, 0.10);
}


/*---Results-Modal---*/

.main-modal3 .modal-dialog-3 {
    max-width: 595px;
}

.main-modal3 .modal-content {
    padding: 32px 26px 0 22px;
}

.attempt-div {
    margin: 0 0 12px;
}

.attempt-div h3 {
    color: #2E2C34;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 12px;
}

.inr-attempt {
    border-radius: 12px;
    border: 1px solid #ECEDED;
    background: #FFF;
    padding: 19px 12px;
    cursor: pointer;
}

.inr-attempt p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.inr-attempt span {
    color: rgba(0, 0, 0, 0.50);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 0 10px;
}

.progress__div {
    width: 135px;
    background: #ECEDED;
    height: 10px;
    border-radius: 12px;
    margin: 0 12px;
}

.progress__div div {
    height: 100%;
    border-radius: 12px;
}

.main-modal3 .modal-header button {
    top: 5px;
    font-size: 12px;
}


/*---List-Words-Modal---*/

.main-modal4 .modal-dialog-4 {
    max-width: 542px;
}

.main-modal4 .modal-content {
    padding: 45px 55px;
}

.card___div .row {
    padding: 0 0 16px;
}

.card__input {
    padding: 0 0 0 20px;
    position: relative;
}

.spkr__btn {
    position: absolute;
    right: 12px;
    top: 8px;
}

.card___div div h3 {
    color: #2E2C34;
    font-size: 20px;
    font-weight: 300;
    line-height: normal;
}

.card__input input {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #ECEDED;
    background: #FFF;
    padding: 14px 12px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.card__input input::placeholder {
    color: #000;
}



/*---Redo-Modal---*/

.main-modal4 .modal-dialog-5 {
    max-width: 450px;
}



/*---Practice-Modal---*/

.main-modal6 .modal-dialog-6 {
    max-width: 595px;
}

.main-modal5 .modal-content {
    padding: 30px 45px;
}

.practice___modal .settings-box label span {
    color: #2E2C34;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 12px;
}

.practice___modal > h3 {
    color: #2E2C34;;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 20px 0 12px;
}

.practice___modal .accordion {
    padding: 0 0 30px;
}

.practice___modal .accordion .accordion-item {
    border-radius: 0;
    overflow: hidden;
    border: none;
    background: transparent;
}

.practice___modal .accordion-titel {
    cursor: pointer;
    margin: 0 0 8px;
}

.practice___modal .accordion-titel h2 {
    color: #84818A;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.practice___modal .accordion-titel img {
    float: right;
}

.practice___modal .accordion-contant {
    padding: 2px 0 12px;
    display: none;
}

.click__btn li button {
    border-radius: 12px;
    border: 1px solid #F6F7F7;
    background: #F6F7F7;
    padding: 2px 15px 4px 6px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 7px 8px 0;
}

.click__btn li button span {
    color: #000;
    font-size: 9px;
    font-weight: 600;
    line-height: 1;
    border-radius: 9px;
    background: #FFF;
    padding: 6.5px 5.2px;
    vertical-align: middle;
    margin: 0 7px 0 0;
}

.click__btn .clk-btn-bg {
    border: 1px solid #00AEA4;
    background: #00AEA4;
    color: #FFF;
    transition: 0.2s all ease-in-out;
}




/*---Results-Details-Modal---*/

.main-modal44 .modal-content {
    padding: 45px 48px;
}

.spelling-div table tr th {
    padding: 0 0 35px;
}

.spelling-div table tr th,
.spelling-div table tr td {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.spelling-div table tr td {
    padding: 0 0 14px;
}

.alex-td img {
    margin: 0 10px 0 0;
}

.gray-alex {
    color: rgba(0, 0, 0, 0.50) !important;
}

.spelling-div table tr td button {
    border-radius: 12px;
    border: 1px solid #00AEA4;
    background: #00AEA4;
    padding: 5px 12px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}



/*---Modal-Result2---*/

.quizz-area {
    padding-top: 150px;
    padding-bottom: 150px;
}

.result-quizz .modal-header button {
    right: 15px;
}


/*
============================
Jessie Page end here
============================
*/









/*
============================
Start Learn start here
============================
*/

.start-learn-area {
    padding: 185px 0 130px;
}

.start-learn-area p,
.quizz-area p {
    color: #2E2C34;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    margin: 16px 0 8px;
}

.start-learn-area span,
.quizz-area a {
    color: #00AEA4;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}



/*
============================
Start Learn end here
============================
*/


/*
============================
writenspeak start here
============================
*/


.writenspeak-area {
    background: #fff;
    width: 100%;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 0 10px rgb(230, 230, 230);
}
.writenspeak-area .input-field {
    z-index: -999;
    opacity: 0;
    position: absolute;
}
.content-box .text-of-typing {
    min-height: 570px;
    width: 80%;
    overflow-y: auto;
}
.content-box .text-of-typing::-webkit-scrollbar {
    width: 0;
}
.content-box .text-of-typing p {
    text-align: justify;
    font-size: 23px;
    letter-spacing: 1px;
    word-break: break-all;
    line-height: 1.5;
}
.content-box {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 36px 36px;
    /*! min-height: 570px; */
}
.content-box .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 12%;
    text-align: center;
    line-height: 32px;
    visibility: hidden;
}
.content .result {
    padding: 19px;
    border-radius: 10px;
    background: #00aea4;
    display: block;

    box-shadow: 0 0 10px rgb(230, 230, 230);
}
.content .result li {
    border-bottom: 1px solid #ccc;
    list-style: none;
}
.content .result li:last-child {
    border-bottom: none;
}

.result li.time,
.result li.errors,
.result li.wpm,
.result li.cpm {
    font-size: 17px;
    color: #020202;
}
.result li.time p,
.result li.errors p,
.result li.wpm p,
.result li.cpm p{
    font-weight: 600;
}


.text-of-typing p span.correct {
    color: #202020;
}
.text-of-typing p span.incorrect {
    color: rgb(236, 25, 25);
}

.text-of-typing p span.active {
    color: #202020;
}
.text-of-typing p span.active::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background: #00AEA4;
    animation: blink 1s ease-in-out infinite;
    opacity: 0;
    border-radius: 20px;
    /*! z-index: 99; */
}
@keyframes blink {
    50% {
        opacity: 1;
    }
}
.text-of-typing p span {
    position: relative;
    color: #CBCBCB;
    display: inline;
}



.progress-area {
    height: 5px;
    background-color: #EDEDED;
    border-radius: 25px;
    margin: 0 30px;
}

.progress-bar {
    height: 5px; /* Adjust height as needed */
    background-color: #00AEA4; /* Progress bar color */
    position: relative;
    width: 0%;
    border-radius: 25px;
}

.practise-footer {
    background: #F2F2F2;
    border-radius: 15px 15px 0 0;
    padding: 20px 30px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.practise-footer button {
    background: #00AEA4;
    padding: 15px 20px;
    border-radius: 15px;
    font-size: 20px;
    color: #fff;
    border: none;
    font-family: "Inter", sans-serif;
}

.practise-footer button[disabled] {
    background: #fff;
    color: #E3E3E3;
}
/*
====================
Practice page pop up
====================
*/

.ws-wordbox {
    border-radius: 20px;
    max-width: 475px;
    margin: 38px 5px 10px 18px;
    position: relative;
    z-index: 0;
    display: none;
    box-shadow: 5px 5px 30px 0 #051E311F;
}

.ws-wordbox.langbox {
    max-width: 448px;
}

.ws-inner {
    padding: 18px 33px 18px 15px;
    background: #fff;
    border-radius: 20px;
}

.ws-inner img {
    cursor: pointer;
}

.close-ws {
    font-size: 16px;
    color: #898989;
    position: absolute;
    right: 15px;
    top: 25px;
}

.ws-wordbox h4 {
    display: flex;
    gap: 10px;
    color: #00AEA4;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 15px;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    width: 100%;
}

.ws-wordbox ul {
    padding-left: 18px;
}

.ws-wordbox ul li {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42;
    list-style-type: decimal;
    list-style-position: outside;
}

.content-box .text-of-typing .ws-wordbox p {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42;
    list-style-type: decimal;
    list-style-position: outside;
    letter-spacing: normal;
    word-break: break-word;
    padding: 0;
}

.content-box .text-of-typing .ws-wordbox p span {
    margin-left: 5px;
}


.ws-wordbox h4 span.langs {
    display: inline-block;
    padding: 4px 11px;
    border-radius: 8px;
    border: none;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.42;
    background: #F6F6F6;
}

.wslang-option {
    display: flex;
    gap: 4px;
    width: 40%;
    justify-content: end;
}
.ws-wordbox h4 span.spech {
    display: inline-block;
    padding: 4px 15px;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42;
    margin: 0;
    color: rgba(0, 174, 164, 1);
    background: rgba(0, 174, 164, 0.1);
}

.ws-wordbox a {
    display: inline-block;
    padding: 12px 16px;
    border-radius: 12px;
    border: 1px solid #000;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.42;
    margin-top: 18px;
}

.ws-wordbox audio {
    border-radius: 5px;
    margin-top: 14px;
    width: 100%;
}

.ws-wordbox .left-to-right {
    direction: rtl;
}

.text-of-typing p.listen-p span.active::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 3px;
    width: 100%;
    background: #00AEA4;
    animation: none;
    opacity: 1;
    border-radius: 20px;
    z-index: 99;
}

/*
====================
chart-area/graph-area
====================
*/

.graph-area {
    border-radius: 16px;
    background: #FFF;
    padding: 28px;
}

.select-date {
    margin-bottom: 35px;
}

.select-date select {
    border-radius: 12px;
    border: 1px solid #F0F0F0;
    background: #FFF;
    padding: 8px 50px 8px 16px;
    background: url('../../../src/static/images/arrow_drop_down.svg');
    background-repeat: no-repeat;
    background-position: 90% center;
}

#__react-alert__ div[style*="text-transform: uppercase"] span {
    text-transform: none !important;  /* Override to prevent all caps */
}

.overwrite {
    width: 100% !important;
    margin-top: 3rem !important;
}


.modal-backdrop {
    transition: opacity 0.3s ease-out;
    background: none;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 1000ms ease-out;
}

.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

/** Practice page test mode. **/
.test-mode {
    width: 92%;
    position: relative;
}

.test-mode textarea {
    min-height: 570px;
    width: 100%;
    border: none;
    resize: none;
    position: relative;
    caret-color: #00AEA4;
    text-align: justify;
    font-size: 23px;
    letter-spacing: 1px;
    word-break: break-all;
    line-height: 1.5;
    color: #202020;
    font-weight: 400;
}

#termCount {
    font-size: 20px;
    letter-spacing: 1px;
    word-break: break-all;
    line-height: 1.5;
    color: #202020;
    font-weight: 400;
    width: 180px;
    background: #F6F7F7;
    text-align: center;
    padding: 7px 0;
    border-radius: 25px;
    margin: 0 auto -36px;
}

#termCount span {
    display: none;
}

#wrdct {
    display: none;
}

#progressBarContainer {
    margin-top: 20px;
}
#progressBar {
    height: 5px;
    background-color: #EDEDED;
    border-radius: 25px;
    margin: 0 30px;
}
#progressBarFill {
    height: 100%;
    width: 0;
    background-color: #00AEA4;
    border-radius: 5px;
}

.hide-cursor .active {
    visibility: hidden;
}

textarea:disabled {
    background-color: white; /* or the original background color */
    color: black; /* or the original text color */
    cursor: not-allowed;
}

.practice__dropdown_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sort__div,
.practice__btn__div {
    display: flex;
}

.practice__btn {
    flex-grow: 1;
}

.settings-button {
    flex-shrink: 0;
}

.student-dashboard-nav-buttons {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 12px;
    padding-left: 1rem;
    margin-left: 1rem;
    border-color: black;
}

.menu-item:hover {
    cursor: pointer;
}

.logo-img {
    max-width: 35px;
    height: auto;
}


.practice__dropdown_box select {
    cursor: pointer;
}

.practice__dropdown_box option {
    cursor: pointer;
}


.test-page-header {
    padding: 10px 0;
    border-bottom: 1px solid #EBEAED;
    margin: 0 35px 12px;
}
